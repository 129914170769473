import {
  BIOPSY,
  BIOPSY_CAPITALS,
  BIOPSY_RESULT,
  CRYO_DATE,
  CRYO_DATE_CAPITALS,
  CRYODEVICE,
  CRYODEVICE_ID,
  EMBRYO_TYPE,
  EMBRYO_NUMBER,
  GRADE,
  GRADE_CAPITALS,
  MATURITY_CAPITALS,
  MATURITY_OPTIONS,
  MATURITY_LEVEL,
  NOTES,
  SPECIMEN_BIOPSY_YES,
  SPECIMEN_BIOPSY_NO,
  SPECIMEN_COUNT,
  SPECIMEN_NOTES,
  SPECIMEN_TYPE,
  SPECIMEN_TYPE_EMBRYO,
  SPECIMEN_TYPE_OOCYTE
} from '@/constants'

const BIOPSY_OPTIONS = [SPECIMEN_BIOPSY_YES, SPECIMEN_BIOPSY_NO].map((value) => ({
  label: value,
  value
}))

export const EMBRYO_HEADER_COLUMNS = ({
  isImport,
  isEdit,
  embryoTypes,
  currentTicketStep,
  modalEdit = []
}) => [
    {
      name: `${CRYODEVICE} ID`,
      component: 'ScanCryodevice',
      key: CRYODEVICE_ID,
      edit: true,
      reprintClick: true,
      columnClass: 'w-48',
      value: (item) => item
    },
    {
      name: 'Reprint Label',
      component: 'reprintLabelHeader',
      columnClass: !currentTicketStep ? 'hidden' : 'w-32'
    },
    {
      name: 'Count',
      key: SPECIMEN_COUNT,
      component: 'DataField',
      fieldType: 'number',
      edit: isImport,
      componentSize: 'small',
      columnClass: 'w-20',
      minValue: 1,
      maxValue: 8,
      maxlength: 1
    },
    {
      name: 'Type',
      key: EMBRYO_TYPE,
      component: 'OptionsField',
      edit: isEdit,
      options: embryoTypes,
      columnClass: 'w-40',
      applyAll: true,
      value: (item) => item.embryo?.embryoType
    },
    {
      name: `${SPECIMEN_TYPE_EMBRYO} #`,
      key: EMBRYO_NUMBER,
      component: 'DataField',
      edit: isEdit,
      columnClass: 'w-32',
      maxlength: 12,
      value: (item) => item.embryo?.embryoNumber
    },
    {
      name: GRADE_CAPITALS,
      key: GRADE,
      component: 'DataField',
      edit: isEdit,
      columnClass: 'w-32',
      maxlength: 12,
      value: (item) => item.embryo?.grade
    },
    {
      name: BIOPSY_CAPITALS,
      key: BIOPSY,
      component: 'OptionsField',
      edit: isEdit,
      columnClass: 'w-20',
      defaultValue: 'No',
      options: BIOPSY_OPTIONS,
      applyAll: true,
      value: (item) => item.embryo?.biopsy
    },
    {
      name: 'Result',
      key: BIOPSY_RESULT,
      component: modalEdit.includes(BIOPSY_RESULT) ? 'DataFieldModal' : 'DataField',
      edit: isEdit,
      maxlength: 255,
      columnClass: 'max-w-10',
      value: (item) => item.embryo?.biopsyResult
    },
    {
      name: NOTES,
      key: SPECIMEN_NOTES,
      component: modalEdit.includes(SPECIMEN_NOTES) ? 'DataFieldModal' : 'DataField',
      edit: isEdit,
      alignContentToLeft: true,
      fieldClasses: 'block p-3',
      block: true,
      tooltip: true,
      maxlength: 255,
      columnClass: 'max-w-10'
    },
    {
      name: 'Current Label ID',
      key: 'currentLabelId',
      component: 'DataField',
      edit: isEdit,
      maxlength: 15,
      columnClass: 'max-w-10'
    }
  ]

export const EGG_HEADER_COLUMNS = ({
  isImport,
  isEdit,
  currentTicketStep,
  modalEdit = []
}) => [
    {
      name: `${CRYODEVICE} ID`,
      component: 'ScanCryodevice',
      key: CRYODEVICE_ID,
      edit: true,
      reprintClick: true,
      columnClass: isEdit ? 'max-w-5' : 'w-48', // This field with should be binded to the current router path to adjust it better to different screens
      value: (item) => item
    },
    {
      name: 'Reprint Label',
      component: 'reprintLabelHeader',
      columnClass: !currentTicketStep ? 'hidden' : 'w-32'
    },
    {
      name: 'Count',
      key: SPECIMEN_COUNT,
      component: 'DataField',
      fieldType: 'number',
      edit: isImport,
      componentSize: 'small',
      columnClass: 'max-w-5',
      minValue: 1,
      maxValue: 8,
      maxlength: 1
    },
    {
      name: 'Type',
      key: SPECIMEN_TYPE,
      component: 'DataField',
      edit: false,
      columnClass: 'max-w-10'
    },
    {
      name: MATURITY_CAPITALS,
      key: MATURITY_LEVEL,
      component: 'OptionsField',
      edit: isEdit,
      columnClass: 'max-w-5',
      options: MATURITY_OPTIONS
    },
    {
      name: 'Current Label ID',
      key: 'currentLabelId',
      component: 'DataField',
      edit: isEdit,
      maxlength: 15,
      columnClass: 'max-w-15'
    },
    {
      name: NOTES,
      key: SPECIMEN_NOTES,
      component: modalEdit.includes(SPECIMEN_NOTES) ? 'DataFieldModal' : 'DataField',
      edit: isEdit,
      alignContentToLeft: true,
      fieldClasses: 'block p-3',
      block: true,
      tooltip: true,
      maxlength: 255,
      columnClass: 'max-w-10'
    }
  ]

export const FREE_TEXT_COLUMN = ({ modalEdit = [] }) => ({
  name: 'Free Text',
  key: 'freeText',
  component: modalEdit.includes('freeText') ? 'DataFieldModal' : 'DataField',
  edit: true, // Free text should always be editable for SMS and EMR.
  alignContentToLeft: true,
  fieldClasses: 'block p-3',
  block: true,
  tooltip: true,
  columnClass: 'flex-grow',
  maxlength: 255
})

export const COUNT_X_TYPE_COLUMN = {
  name: 'Count x Type',
  edit: false,
  component: 'DataField',
  columnClass: 'max-w-2 w-1/6',
  value: (item) => {
    let countByType = ''
    if (item?.oocyte) {
      countByType = `${item.specimenCount} x ${item?.oocyte?.maturityLevel ? item.oocyte.maturityLevel : SPECIMEN_TYPE_OOCYTE
        }`
    } else if (item?.embryo) {
      countByType = `${item.specimenCount} x ${item?.embryo?.embryoType ? `D${item.embryo.embryoType}` : SPECIMEN_TYPE_EMBRYO
        }`
    }
    return countByType
  }
}

export const CRYO_DATE_COLUMN = {
  name: CRYO_DATE_CAPITALS,
  key: CRYO_DATE,
  component: 'DateField',
  edit: true,
  columnClass: 'max-w-24'
}
