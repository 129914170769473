export const STEP_SELECT_LOCATION = 'BiorepositorySelectLocation'
export const STEP_SELECT_PATIENT = 'BiorepositorySelectPatient'
// export const STEP_SELECT_BEACONS = 'BiorepositorySelectBeacons'
export const STEP_TICKET_SUMMARY = 'BiorepositoryTicketSummary'
export const ROUTE_MOVE_TICKET = 'BiorepositoryMoveTicket'
export const STEP_BIOREPOSITORY_FLIGHTBOARD = 'BiorepositoryFlightBoard'
export const STEP_ADD_CRYOBEACONS = 'BiorepositoryAddCryobeacons'
export const STEP_SELECT_RELOCATION_TICKETS = 'BiorepositorySelectRelocationTickets'
export const STEP_TICKET_CREATED = 'BiorepositoryTicketCreated'
export const CRYOSHIPPER_CONFIRM = 'BiorepositoryCryoShipperConfirm'
export const CRYOBEACON_DETAILS = 'BiorepositoryCryobeaconDetails'
