<template>
  <MainContainer gridSlots="patients">
    <TopHeader :back-btn="false">
      <HeaderSummary
        v-if="!isBioRepoTicketCreated"
        :cryobeaconsAmount="cryobeaconsAmount"
        :sourceLocation="sourceLocation"
        :destinationLocation="destinationLocation"
        :selectedPatient="selectedPatient"
      />

      <p v-else>{{ textHeaderCreated }}</p>
    </TopHeader>

    <ActionBar :colsDistribution="!isBioRepoTicketCreated ? '10/2' : '6/6'">
      <template v-slot:left-side>
        <dynamic-title titleType="h2" floatTo="left">
          <span v-if="!isBioRepoTicketCreated">
            Please confirm that everything is correct and press Create Ticket
          </span>

          <span v-else> Your ticket has been created! </span>
        </dynamic-title>
      </template>

      <template v-slot:right-side>
        <ButtonDynamic
          v-if="isBioRepoTicketCreated"
          btnText="Print"
          btnType="button"
          btnStyle="secondary"
          @click="showPrinterModal"
          showIcon
          fontAwesomeIconClass="print"
          addMarginLeft
        />

        <ButtonDynamic
          v-if="!isBioRepoTicketCreated"
          addMarginLeft
          btnStyle="primary"
          btnText="Create Ticket"
          btnType="button"
          @click="handleTicketCreation"
          fontAwesomeIconClass="check-circle"
          showIcon
        />

        <ButtonDynamic
          v-else
          addMarginLeft
          btnStyle="primary"
          btnText="Return to Flight Board"
          btnType="button"
          @click="handleGoToFlightboard"
          fontAwesomeIconClass="list-alt"
          showIcon
        />
      </template>
    </ActionBar>

    <section>
      <Paginator
        :total-items-to-display="cryobeaconsAmount"
        :max-paginators-count-per-view="maxPaginatorsCountPerView"
        :currentActivePage="currentActivePage"
        @handlePageChange="handlePageChange"
      />

      <span v-for="(ticket, key) in ticketsToFinish" :key="`ticket-to-finish-${key}`">
        <TicketToFinish
          v-if="shouldShowTicket({ currentActivePage, key })"
          :ticket="ticket"
          :sourceLocation="sourceLocation"
          :destinationLocation="destinationLocation"
          :newTicketData="newTicketData"
          :ticketId="currentTicketId"
        />
      </span>
    </section>
    <ModalPrint
      v-if="isPrinterModalVisible"
      :selected-tickets="selectedTickets"
      :tickets-to-print="selectedTicketsToPrint"
      @close-printer-modal="closePrinterModal"
    />
  </MainContainer>
</template>

<script setup>
import dayjs from 'dayjs'
import toast from '@/config/toast'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import HeaderSummary from '@/components/Biorepository/HeaderSummary/HeaderSummary.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import Paginator from '@/components/Paginator/Paginator.vue'
import TicketToFinish from '@/components/TicketToFinish/TicketToFinish.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import ModalPrint from '@/components/ModalPrint/ModalPrint.vue'

import { fetchTicketData } from '@/services/tickets'

import {
  MAX_PAGINATORS_COUNT_PER_VIEW,
  MOVE_LOCATION
} from '@/constants'

import { STEP_BIOREPOSITORY_FLIGHTBOARD } from '@/constants/moveLocationTicketSteps'
import { computed, ref } from 'vue'

import useGetters from '@/composables/useGetters'
import useActions from '@/composables/useActions'
import { useRouter } from 'vue-router'

const router = useRouter()

const maxPaginatorsCountPerView = ref(MAX_PAGINATORS_COUNT_PER_VIEW)
const currentActivePage = ref(1)
const isBioRepoTicketCreated = ref(false)
const ticketDetails = ref([])

const { appBaseConfig } = useGetters('authModule')

const { sourceLocation,
destinationLocation,
selectedPatient,
selectedBeacons,
destinationCryorobot } = useGetters('biorepoTicketModule')

const isPrinterModalVisible = ref(false)

const cryobeaconsAmount = computed(() =>{
  return selectedBeacons.value.length
})
const ticketsToFinish = computed(()=> {
  return composeTicketsToFinish({ beacons: selectedBeacons.value, patient: selectedPatient.value })
})
const currentTicketId = computed(() => {
  return ticketDetails.value.length > 0
    ? `${ticketDetails.value[currentActivePage.value - 1].ticketId}`
    : ''
})
const textHeaderCreated = computed(() => {
  return `
    You have created ${cryobeaconsAmount.value} 
    ${cryobeaconsAmount.value > 1 ? 'tickets' : 'ticket'}
  `
})
const newTicketData = computed(() =>{
  return ticketDetails.value[currentActivePage.value - 1]
})


const { createBiorepoTicket } = useActions('biorepoTicketModule')
const { displaySpinner, hideSpinner } = useActions('spinnerModule')

const  handleTicketCreation = async () => {
  displaySpinner()
  try {
    const response = await createBiorepoTicket({
      sourceLocation: sourceLocation.value,
      destinationLocation: destinationLocation.value,
      selectedBeacons: selectedBeacons.value,
      selectedPatient: selectedPatient.value,
      destinationCryorobot: destinationCryorobot.value
    })
    const tickets = response.data
    const ids = tickets
      .map((item) => {
        if (item.ticketId) {
          return item.ticketId
        }
        return item
      })
      .join()
    const { collectionProtocolId } = appBaseConfig.value
    // stopped using store's ticketDetail as it only holds one ticket, causing repeated info on multiple tickets, and only actually needed in this component
    // better fetch data and use here
    ticketDetails.value = await fetchTicketData(collectionProtocolId, ids)
    toast.success('Ticket created successfully!')
    isBioRepoTicketCreated.value = true
  } catch (error) {
    toast.error({ title: error.message })
  } finally {
    hideSpinner()
  }
}
const handlePageChange = (pageId) => {
  currentActivePage.value = pageId
}
const composeTicketsToFinish = ({ beacons, patient }) => {
  return beacons.map((beacon) => ({
    cryoDevice: [...(beacon?.specimens || [])],
    procedureType: MOVE_LOCATION,
    specimenType: '',
    patient,
    visit: {
      procedureDateFrom: dayjs().valueOf()
    }
  }))
}
const shouldShowTicket = ({ currentActivePage, key }) => {
  const INDEX = 1
  return currentActivePage === key + INDEX
}
const handleGoToFlightboard = () => {
  router.push({ name: STEP_BIOREPOSITORY_FLIGHTBOARD })
}

const selectedTickets = computed(() => {
  return ticketsToFinish.value || []
})
const selectedTicketsToPrint = computed(() => {
  return ticketDetails.value || []
})

const closePrinterModal = () => {
  isPrinterModalVisible.value = false
}
const showPrinterModal = ()  => {
  isPrinterModalVisible.value = true
}
</script>
