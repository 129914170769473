import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import toast from '@/config/toast'
import {
  FILTER_TODAY,
  DYNAMIC_HEADER_ERROR,
  PROCEDURE_TYPE_SINGLE_IMPORT,
  TICKET_COMPLETE_CAPITALS
} from '@/constants'
import { Patient } from "@/types"

dayjs.extend(utc)

type formingflightBoardModelType = {
  filter: string
  sort: () => void
  value: []
  checkSpecimenImport: (ticket: {}) => void
}

type formingflightBoardHasTicketsType = {
  filter: formingflightBoardModelType['filter']
  flightBoardModel: {
    options: {
      length: number
    }[]
  }
}

type getShippingSourceNameType = {
  externalClinics: {
    id: string
    name: string
  }[]
  shippingSourceId: number | string
}

type checkSpecimenImportType = {
  ticket: {
    procedureType: string
    cryoDate: string
    sources: {
      donorId: string
      shippingSourceId: number | string
    }[]
  }
  externalClinics: []
}

interface IdateRangeHelper {
  dateFromMsUtc: number
  dateToMsUtc: number
}

const formingflightBoardModel = ({
  filter,
  sort,
  value,
  checkSpecimenImport
}: formingflightBoardModelType) => {
  try {
    if (filter === FILTER_TODAY) {
      const options = value.map((el: []) => el.map((ticket) => checkSpecimenImport(ticket)))

      return {
        sort,
        options
      }
    }

    return {
      sort,
      options: value
    }
  } catch (error) {
    toast.error({ title:DYNAMIC_HEADER_ERROR})
    throw new Error(`formingflightBoardModel ---> error : ${error}`)
  }
}

const formingflightBoardHasTickets = ({
  filter,
  flightBoardModel
}: formingflightBoardHasTicketsType) => {
  try {
    switch (filter) {
      case FILTER_TODAY:
        return (
          !!flightBoardModel?.options[0]?.length ||
          !!flightBoardModel?.options[1]?.length ||
          !!flightBoardModel?.options[2]?.length
        )
      default:
        return !!flightBoardModel?.options.length
    }
  } catch (error) {
    toast.error({ title:DYNAMIC_HEADER_ERROR})
    throw new Error(`formingflightBoardHasTickets ---> error : ${error}`)
  }
}

const getShippingSourceName = ({
  externalClinics,
  shippingSourceId
}: getShippingSourceNameType) => {
  const [shippingSource] = externalClinics.filter((clinic) => clinic?.id === shippingSourceId)
  return shippingSource?.name || ''
}

const checkSpecimenImportHelper = ({ ticket, externalClinics }: checkSpecimenImportType) => {
  let sourcesValue = {}

  if (ticket.procedureType === PROCEDURE_TYPE_SINGLE_IMPORT) {
    if (ticket.sources) {
      const imported = dayjs(Number(ticket.cryoDate)).format('DDMMMYYYY HH:MM A')
      const donorId = ticket.sources?.[0]?.donorId || ''
      const shippingSource =
        getShippingSourceName({
          externalClinics,
          shippingSourceId: Number(ticket.sources?.[0]?.shippingSourceId)
        }) || ''

      sourcesValue = {
        items: [
          {
            imported,
            donorId,
            shippingSource
          }
        ],
        disableHover: false,
        image: 'importCryoBeacon'
      }
    } else {
      sourcesValue = {
        items: [],
        image: 'importCryoBeacon'
      }
    }
  }
  return {
    ...ticket,
    sourcesValue
  }
}

const reversePatientName = (patient: Patient) => {
  if (!patient) return '-'
  return `${patient.lastName}, ${patient.firstName}`
}

const changeCryoShipperToPascal = (name) => name === 'Cryoshipper' ? 'CryoShipper' : name


const dateRangeHelper = (isBiorepositoryFlighboard, ticketState): IdateRangeHelper => {
  const nowDate = dayjs().utc()
  const endOfToday = nowDate.endOf('day').valueOf()
  const beginningOfToday = nowDate.endOf('day').valueOf()
  const dateMonthAhead = nowDate.add(30, 'day').endOf('day').valueOf()
  const sevenDaysAgo = nowDate.add(-7, 'day').startOf('day').valueOf()
  const beginningOftime = dayjs('01/01/1970').startOf('day').valueOf()
  const dateThreeMonthsAgo = nowDate.add(-90, 'day').startOf('day').valueOf()

  let dateFromMsUtc = beginningOftime
  let dateToMsUtc = dateMonthAhead

  if (ticketState === TICKET_COMPLETE_CAPITALS) {
    if (isBiorepositoryFlighboard) {
      dateFromMsUtc = dateThreeMonthsAgo
      dateToMsUtc = beginningOfToday
    } else {
      dateFromMsUtc = sevenDaysAgo
      dateToMsUtc = endOfToday
    }
  }

  return { dateFromMsUtc, dateToMsUtc }
}

export {
  formingflightBoardModel,
  formingflightBoardHasTickets,
  checkSpecimenImportHelper,
  reversePatientName,
  dateRangeHelper,
  changeCryoShipperToPascal
}
