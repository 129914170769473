<template>
  <div
    class="absolute left-0 top-0 right-0 bottom-0 z-50"
    :class="[
      {
        'pointer-events-none': !showMenu,
        'pointer-events-auto': showMenu
      }
    ]"
    data-test="main-menu-wrapper"
    @click="closeMenu"
  >
    <aside
      data-test="main-menu-container"
      class="fixed top-0 right-0 z-50 pt-20 w-[40%] xl:w-1/3 max-w-sm h-menu m-3 bg-gradient-to-r from-tmrw-gradient-green-start to-tmrw-gradient-green-end shadow-md ease-in-out transform transition-all duration-500 flex flex-col justify-between"
      :class="showMenu ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0 invisible'"
    >
      <button-dynamic
        btnStyle="closeMenu"
        btnType="button"
        show-icon
        floatTo="none"
        fontAwesomeIconClass="times"
        @click="closeMenu"
      />
      <ul
        data-test="menu-items"
        class="text-xl font-exo text-tmrw-blue-dark overflow-auto"
        @click="closeMenu"
      >
        <li
          v-for="item in getUserMenuItems"
          :data-test="item.id"
          :id="item.id"
          :key="item.id"
          class="pl-8 py-3"
          :class="item.isDivider ? 'text-sm pb-0' : 'cursor-pointer hover:bg-tmrw-green'"
          @click="goTo(item)"
        >
          <i v-if="!item.isDivider && item.icon" class="fa-md mr-5" :class="item.icon" />
          {{ item.label }}
        </li>
        <li class="mx-4"><hr class="border-solid border-0.5 border-tmrw-blue-light" /></li>
        <li
          id="menu-item-sign-out"
          class="hover:bg-tmrw-green cursor-pointer pl-8 py-3"
          @click="handleLogout"
        >
          <i class="fas fa-sign-out-alt fa-md mr-5" />
          Sign out
        </li>

        <li id="tmrw-institute" class="hover:bg-tmrw-green cursor-pointer pl-8 py-3">
          <i class="far fa-file-alt fa-md mr-5" />
          <a href="https://institute.tmrw.org/user-manual" target="_blank" rel="noopener noreferrer"
            >TMRW Institute</a
          >
        </li>

        <li
          id="terms-and-conditions"
          class="hover:bg-tmrw-green cursor-pointer pl-8 py-3 text-sm"
          @click="openTermsAndConditions"
        >
          <i class="far fa-file-alt fa-md mr-5"></i>
          Terms and Conditions
        </li>
        <li
          id="privacy-policy"
          class="hover:bg-tmrw-green cursor-pointer pl-8 py-3 text-sm"
          @click="openPrivacyPolicy"
        >
          <i class="far fa-file-alt fa-md mr-5"></i>
          Privacy Policy
        </li>
      </ul>
      <section class="bottom-0 my-8 pl-8 font-inconsolata">
        <img :src="ivfosLogo" class="w-32 mb-2" alt="ivfos-tmrw-logo" />
        <p class="text-tmrw-blue-dark text-sm">Version API #{{ apiVersion }}</p>
        <p class="text-tmrw-blue-dark text-sm">Version APP #{{ appVersion }}</p>
        <p class="text-tmrw-blue-dark text-sm">TMRW {{ CRYOROBOT }} 1</p>
        <div class="flex flex-col lg:flex-row my-4 lg:items-center">
          <p class="py-2">
            <strong>Support:</strong>
            <a class="text-tmrw-blue-dark text-sm pl-1" :href="`mailto:${appSupportEmail}`">{{
              appSupportEmail
            }}</a>
          </p>
          <div class="w-px lg:h-10 bg-tmrw-blue-dark ml-2" />
          <div class="pl-2">
            <p class="text-tmrw-blue-dark text-sm">
              <a :href="`tel:${appSupportPhoneUS}`">(US) {{ appSupportPhoneUS }}</a>
            </p>
            <p class="text-tmrw-blue-dark text-sm">
              <a :href="`tel:${appSupportPhoneUK}`">(UK) {{ appSupportPhoneUK }}</a>
            </p>
          </div>
        </div>
        <div class="flex items-center gap-4 xl:gap-8 flex-wrap">
          <img
            class="sm:w-28 lg:w-32 xl:w-40"
            src="@/assets/tmrw-factory-cyan.svg"
            alt="Tmrw Factory Cyan Logo"
          />
          <img
            class="sm:w-28 lg:w-32 xl:w-40"
            src="@/assets/EC-REP-cyan.svg"
            alt="EC-REP cyan logo"
          />
          <img class="w-12" src="@/assets/CE-cyan.svg" alt="CE cyan logo" />
        </div>
      </section>
    </aside>
  </div>
</template>

<script setup>
import MENU_ITEMS from './menuItems'
import { USER_ADMINISTRATOR } from '@/constants'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import * as ss from '@/config/session-storage-help'
import { isPropertyValid } from '@/helpers/featureFlags'
import { CRYOROBOT } from '@/constants'
import { IVFOS_TMRW_BLUE_LOGO } from '@/constants/images'
import { computed, ref, inject } from 'vue'
import useActions from '@/composables/useActions'
import useGetters from '@/composables/useGetters'
import { useRouter } from 'vue-router'

const router = useRouter()

defineProps(['closeMenu', 'showMenu'])

const mixpanel = inject('mixpanel')

const ivfosLogo = ref(IVFOS_TMRW_BLUE_LOGO)

const { resetTicketProcess } = useActions('newTicketModule')
const { logout } = useActions('authModule')
const { toggleTermsAndConditionsModal, togglePrivacyPolicyModal } = useActions('appInfoModule')

const { loggedUserInfo, appBaseConfig } = useGetters('authModule')

const { appVersion, apiVersion, appSupportEmail, appSupportPhoneUS, appSupportPhoneUK } =
  useGetters('appInfoModule')

const isAdmin = computed(() => {
  return loggedUserInfo.value.userType === USER_ADMINISTRATOR
})
const getUserMenuItems = computed(() => {
  return MENU_ITEMS.filter((item) => {
    if (item.onlyIfSiteProperty) {
      let propertiesValid = true
      Object.entries(item.onlyIfSiteProperty).forEach(([k, v]) => {
        propertiesValid = propertiesValid && isPropertyValid(k, v)
      })
      if (!propertiesValid) return false
    }

    if (item.onlyIfAdmin) {
      return isAdmin.value
    }

    if (item.onlyIfCanBulkUpload) {
      return canBulkUpload.value
    }

    return true
  })
})

const canBulkUpload = computed(() => {
  const sessionUser = ss.getFieldSessionStorage('user')
  return (
    (loggedUserInfo.value.permissions &&
      loggedUserInfo.value.permissions.includes('BULK_SPECIMEN_UPLOAD') &&
      loggedUserInfo.value.userType === USER_ADMINISTRATOR) ||
    (sessionUser.permissions &&
      sessionUser.permissions.includes('BULK_SPECIMEN_UPLOAD') &&
      loggedUserInfo.value.userType === USER_ADMINISTRATOR)
  )
})

const goTo = (menuItem) => {
  if (router.currentRoute.value.path !== menuItem.route && !menuItem.isDivider) {
    const { redirectedFrom, name: viewName, currentRoute } = router.currentRoute.value
    const { hostname } = window.location
    mixpanel.track(`Click on ${menuItem.label} Menu Item in Side Menu`, {
      component: 'Side Menu Item',
      'current-route': currentRoute,
      'view-name': viewName,
      'redirected-from': redirectedFrom,
      'clinic-name': appBaseConfig.value.clinicName,
      'site-name': appBaseConfig.value.siteName,
      'user-name': loggedUserInfo.value.loginName,
      'user-type': loggedUserInfo.value.userType,
      hostname
    })
    router.push(menuItem.route)
  }
}
const handleLogout = () => {
  logout({
    resetTicketProcess,
    router
  })
}
const openTermsAndConditions = () => {
  toggleTermsAndConditionsModal()
}
const openPrivacyPolicy = () => {
  togglePrivacyPolicyModal()
}
</script>
