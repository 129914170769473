<template>
  <div class="flex w-2/5 scroll-table-status-field-element">
    <Tooltip
      v-for="icon in statusIcons"
      :content="icon.tooltip"
      placement="left"
      :key="icon.tooltip"
    >
      <div class="relative">
        <i class="p-3 leading-10 text-xl" :class="icon.icon" @click="icon.onClick" />
        <i v-if="icon.subIcon" class="p-0.5 sub-icon" :class="icon.subIcon" />
      </div>
    </Tooltip>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useStore } from 'vuex'
import {
  TICKET_COMPLETE,
  EMR_SOURCE_ID,
  TICKET_IN_PROGRESS,
  INVENTORY_UPDATE_REQUIRED,
  INVENTORY_UPDATE_DONE
} from '@/constants'
import { INVENTORY_UPDATE_ROUTE, SELECT_EMRBYOLOGIST_ROUTE } from '@/constants/routes'
import { isFreezeProcedure, isThawProcedure } from '@/helpers/ticketState'
import Tooltip from '@/components/TooltipComponent/Tooltip.vue'
import { Ticket } from '@/types/ticket'

dayjs.extend(utc)

type StatusIcon = {
  icon: string
  tooltip: string
  onClick?: Function
  subIcon?: string
}

const router = useRouter()

const props = defineProps({
  value: {
    type: [Object, String],
    required: true
  },
  item: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const store = useStore()

const statusIcons = computed(() => {
  const icons: StatusIcon[] = []
  const ticket = props.item
  const {
    procedureTime,
    state,
    source,
    procedureName,
    inventoryUpdate,
    performedOn,
    embryologistId,
    ticketId,
    identificationNumber
  } = ticket

  const isEmrTicket = source && source === EMR_SOURCE_ID
  const isSingleImport = procedureName.toLowerCase().includes('single import')
  const isBatchTicket = procedureName.toLowerCase().includes('batch')
  const isComplete = state === TICKET_COMPLETE
  const begginingOfDay = dayjs().utcOffset(0).startOf('day').valueOf()

  // Incomplete Tickets from the day before and on are Overdue
  const isOverDue = procedureTime < begginingOfDay && !isComplete

  const handleInventory = async () => {
    await store.dispatch('selectedTicketsModule/setSelectedTickets', [ticket])
    if (
      (isFreezeProcedure(ticket as Ticket) || isThawProcedure(ticket as Ticket)) &&
      !embryologistId
    ) {
      router.push({
        path: SELECT_EMRBYOLOGIST_ROUTE(ticketId),
        query: {
          procedureTime,
          performedOn: performedOn || null,
          embryologistId: embryologistId || null
        }
      })
    } else {
      const inventoryUpdateRoute = INVENTORY_UPDATE_ROUTE(identificationNumber, ticketId)
      router.push(inventoryUpdateRoute)
    }
  }

  if (isOverDue) {
    icons.push({
      icon: 'far fa-clock text-tmrw-error',
      tooltip: 'Overdue'
    })
  }
  if (isEmrTicket) {
    icons.push({
      icon: 'fa fa-lock',
      tooltip: 'EMR'
    })
  }
  if (isSingleImport) {
    icons.push({
      icon: 'fa fa-file-medical',
      tooltip: 'Import'
    })
  }

  if (state === TICKET_IN_PROGRESS) {
    icons.push({
      icon: 'fa fa-history',
      tooltip: TICKET_IN_PROGRESS
    })
  } else if (state === TICKET_COMPLETE) {
    icons.push({
      icon: 'fa fa-check-circle text-tmrw-success',
      tooltip: TICKET_COMPLETE
    })
  }

  if (isBatchTicket) {
    icons.push({
      icon: 'fa fa-link',
      tooltip: 'Batch'
    })
  }
  if (state !== TICKET_COMPLETE) {
    if (inventoryUpdate === INVENTORY_UPDATE_REQUIRED) {
      icons.push({
        icon: 'far fa-edit bg cursor-pointer text-tmrw-blue-dark',
        tooltip: 'Update Inventory',
        onClick: handleInventory
      })
    } else if (inventoryUpdate === INVENTORY_UPDATE_DONE) {
      icons.push({
        icon: 'far fa-edit bg text-tmrw-success',
        tooltip: 'Inventory Updated',
        subIcon: 'far fa-check-circle text-tmrw-success',
        onClick: handleInventory
      })
    }
  }

  return icons
})
</script>
<style lang="scss">
.bg {
  @apply w-10 h-10 rounded-full bg-white shadow-icon;
}
.sub-icon {
  @apply w-4 h-4 shadow-icon leading-4 absolute text-xs -bottom-1 -right-1 rounded-full bg-white;
}
</style>
