<template>
  <div
    class="z-20 modal-view flex items-center justify-center fixed left-0 top-0 w-screen h-screen bg-black bg-opacity-50"
    data-test="modal-print-container"
  >
    <div class="bg-white p-6 w-3/12 rounded-md text-black">
      <ActionBar data-test="customize-beacons__action-bar" colsDistribution="9/3">
        <template v-slot:left-side>
          <DynamicTitle titleType="h3-blue" floatTo="left">Select Printer</DynamicTitle>
        </template>
        <template v-slot:right-side>
          <ButtonDynamic
            btnText=""
            btnType="button"
            btnStyle="close-button-blue"
            showIcon
            fontAwesomeIconClass="times-circle"
            @click="handleClose"
          />
        </template>
      </ActionBar>
      <div class="mt-8">
        <div>
          <select v-model="selectedPrinter" class="mb-8">
            <option value="pdf">Print PDF</option>
            <option v-for="(printer, index) in activePrinters" :key="index" :value="printer.uid">
              {{ getPrinterName(printer.name) }}
            </option>
          </select>
        </div>
        <ButtonDynamic
          btnText="Print"
          btnType="button"
          btnStyle="primary"
          showIcon
          fontAwesomeIconClass="print"
          floatTo="right"
          @click="sendPrintJob"
          :isLoading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'

import { onMounted, ref } from 'vue'

import { sortSpecimensByCryodeviceId } from '@/utils'
import { selectedTicketToPdf } from '@/helpers/printPDF/helpers'
import { printTickets, getInstalledPrinters, setPrinterName } from '@/modules/printer'

import { ZEBRA_TICKET_PRINTERS_ID } from '@/constants'

import useActions from '@/composables/useActions'

const props = defineProps({
  selectedTickets: {
    type: Array,
    default: () => [],
    required: false
  },
  ticketsToPrint: {
    type: Array,
    default: () => [],
    required: false
  }
})

const { getExternalClinics } = useActions('patientsModule')

const selectedPrinter = ref('pdf')
const activePrinters = ref([])
const loading = ref(true)


const handleClose = () => {
  emit('closePrinterModal')
}

const emit = defineEmits(['closePrinterModal'])

onMounted(async () => {
  // Browser Print Setup

  const localPrinters = await getInstalledPrinters()
  localPrinters.printer.forEach((printer) => {
    ZEBRA_TICKET_PRINTERS_ID.forEach((printerId) => {
      if (printer.name.includes(printerId)) {
        activePrinters.value.push(printer)
        selectedPrinter.value = printer.uid
      }
    })
  })
 
    loading.value = false

})  
   

const getPrinterName = (printerName) => {
  return setPrinterName(printerName)
}

const sendPrintJob = () => {
  if (selectedPrinter.value !== 'pdf') {
    activePrinters.value.forEach((printer) => {
      if (printer.uid === selectedPrinter.value) {
        const selectedTicketsToPrint = sortSpecimensByCryodeviceId(props.selectedTickets || [])
        printTickets(printer, selectedTicketsToPrint)
      }
    })
  } else {
    printPDFTicket()
  }
  handleClose()
}

const printPDFTicket = async () => {
  const externalClinics = await getExternalClinics()
  const params = {
    selectedTickets: sortSpecimensByCryodeviceId(props.selectedTickets || []),
    selectedTicketsToPrint: sortSpecimensByCryodeviceId(props.ticketsToPrint || []),
    externalClinics
  }
  return selectedTicketToPdf(params)
}
</script>
