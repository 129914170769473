<template>
  <div class="flex">
    <div
      v-for="(item, index) in items"
      :key="index"
      :data-test="`export-reason-${getReasonSlug(item.label)}`"
    >
      <div
        class="text-xl text-tmrw-blue font-bold leading-6 w-72 bg-white p-4 mr-4 cursor-pointer rounded-lg"
      >
        <label :for="item.value" class="flex items-center">
          <input
            :data-test="`radio-export-reason-${getReasonSlug(item.label)}`"
            class="mr-4"
            type="radio"
            name="reason"
            :value="item.value"
            :id="item.value"
            v-model="reason"
            @change="syncData"
          />

          {{ item.label }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  TRANSFER_OUT,
  DISCARD,
  DONATION,
  OTHER,
  TRANSFER_OUT_VALUE,
  DISCARD_VALUE,
  DONATION_UPPERCASE,
  OTHER_VALUE
} from '@/constants'

import {
  isFeatureEnabled,
  DONATE_TO_PATIENT_ENABLED_FF,
  DONATE_TO_RESEARCH_ENABLED_FF
} from '@/helpers/featureFlags'
import { computed, ref } from 'vue'

const reason = ref('')

const emit = defineEmits(['dataSync'])

const items = computed(() => {
  const _items = [
    { label: TRANSFER_OUT, value: TRANSFER_OUT_VALUE },
    { label: DISCARD, value: DISCARD_VALUE }
  ]
  if (
    isFeatureEnabled(DONATE_TO_PATIENT_ENABLED_FF) ||
    isFeatureEnabled(DONATE_TO_RESEARCH_ENABLED_FF)
  ) {
    _items.push({ label: DONATION, value: DONATION_UPPERCASE })
  }
  _items.push({ label: OTHER, value: OTHER_VALUE })
  return _items
})

const syncData = () => {
  emit('dataSync', { type: reason.value })
}
const getReasonSlug = (slug) => {
  return slug.replace(/\s+/g, '-').toLowerCase()
}
</script>
