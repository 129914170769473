<template>
  <MainContainer data-test="details-view" grid-slots="3">
    <loading-ui v-if="isLoaderVisible" modal message="Validating selected unit space" />
    <top-header @backStep="handleClickBack">
      <HeaderInfo />
    </top-header>
    <ActionBar data-test="details__action-bar" cols-distribution="9/3">
      <template v-slot:left-side>
        <dynamic-title title-type="h2" float-to="left">
          Please record the details of the Cryodevices
        </dynamic-title>
        <TicketStatusTags :is-emr="isEMRTicket" />
      </template>
      <template v-slot:right-side>
        <button-dynamic
          v-if="isMethodFreeze"
          data-test="details__outside-labels-button"
          btn-type="button"
          btn-style="secondary"
          btn-text="Non-ivfOS Cryolabel"
          show-icon
          addMarginRight
          font-awesome-icon-class="tag"
          :is-disabled="isButtonDisabled"
          @click="handleOutsideLabels"
        />
        <button-dynamic
          data-test="details__print-labels-button"
          btn-type="button"
          btn-style="primary"
          :btn-text="buttonText"
          :is-disabled="isButtonDisabled"
          show-icon
          :font-awesome-icon-class="buttonIcon"
          @click="handleBeaconUpdate"
        />
      </template>
    </ActionBar>
    <section>
      <BreadCrumbs :items="getActiveBreadcrumbs" />
      <div class="w-full rounded-tl-md rounded-tr-md bg-white py-2 px-4">
        <div class="flex">
          <div class="mr-6">
            <CustomSelect
              dataTest="label-scan__destination"
              class="bg-tmrw-green-light p-2 rounded-lg w-54"
              customText="STORE TO"
              :options="destinationOptions"
              isDefaultOptionDisabled="true"
              hasLargePadding
              @update:modelValue="onChangeDestination"
              :modelValue="defaultDestinationValue"
            />
          </div>
          <div class="flex items-start justify-center flex-col mr-6">
            <p
              data-test="cryobeacon-count-title"
              class="text-tmrw-blue uppercase text-xs font-bold -mt-1"
            >
              {{ CRYOBEACON }} count
            </p>
            <CryobeaconNumber
              :activeBeaconCounter="computeActiveBeaconCounterMsg"
              :showBackground="false"
              titleType="h1-blue-light-medium-bold"
              customPadding="p-0"
            />
          </div>
          <div class="text-tmrw-blue flex items-start justify-center flex-col mr-6">
            <p class="uppercase text-xs font-bold">Infectious Screening Status</p>
            <ScreeningStatusLabel
              :id="cryodeviceLabelsData[0]?.screeningStatus"
              class="pr-6 font-semibold text-tmrw-blue"
              text-color="yellow"
              icon-color="yellow"
              :is-full-width="false"
            />
          </div>
          <div class="flex items-start justify-center flex-col mr-6">
            <p
              class="text-tmrw-blue font-semibold mb-2"
              data-test="details__action-bar--cryodevice-type-title"
            >
              {{ CRYODEVICE }} Type
            </p>
            <p class="text-tmrw-blue mb-2" data-test="details__action-bar--cryodevice-type">
              {{ cryoDeviceTypeValue }}
            </p>
          </div>
          <div
            v-if="isMethodImport"
            class="col-span-2 flex items-start justify-center flex-col mr-6"
          >
            <p class="text-tmrw-blue font-semibold mb-2" data-test="details__action-bar--donor-id">
              Donor Id
            </p>
            <p class="text-tmrw-blue mb-2" data-test="details__action-bar__donor-id">
              {{ currentDonorId }}
            </p>
          </div>
          <div
            v-if="isMethodImport"
            class="col-span-2 flex items-start justify-center flex-col mr-6"
          >
            <p
              class="text-tmrw-blue font-semibold mb-2"
              data-test="details__action-bar--shipping-source"
            >
              Shipping Source
            </p>
            <p class="text-tmrw-blue mb-2" data-test="details__action-bar__shipping-source">
              {{ currentClinicName }}
            </p>
          </div>
        </div>
      </div>
      <EditableTable
        v-if="cryodeviceLabelsData.length"
        :items="cryodeviceLabelsData"
        :edit="isDetailEditable"
        :modalEdit="modalEdit"
        :method-is-import="isMethodImport"
        :specimen-type="selectedSpecimenType"
        :field-errors="!areMandatoryFieldsValid()"
        showCryodeviceValueOnly
        class="p-2 bg-white"
        tab-direction="column"
        @changeValue="handleSpecimenChange"
      />
    </section>
    <preview-labels
      :show-label-modal="showPrintLabelModal"
      :labels="cryodeviceLabelsData"
      @closeLabelModal="handleClosePrintModal"
      @clickPrint="handleContinue"
    />
    <ModalWindow
      modal-title="Use Non-ivfOS Cryolabels"
      title-type="h2-blue-dark"
      cols-distribution="9/3"
      :isModalOpen="isModalOpen"
      is-bold
      @closeModalWindow="closeModalWindow"
    >
      <p class="my-8 text-tmrw-blue text-2xl">
        A scannable barcode must be present to use a<br />Non-ivfOS Cryolabel
      </p>
      <button-dynamic
        btnText="Continue"
        btnType="button"
        btnStyle="primary"
        @click="handleConfirmOutsideLabels"
      />
      <button-dynamic
        btnText="Cancel"
        btnType="button"
        btnStyle="secondary"
        addMarginRight
        @click="closeModalWindow"
      />
    </ModalWindow>
  </MainContainer>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import ActionBar from '@/components/ActionBar/ActionBar.vue'
import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue'
import ButtonDynamic from '@/components/ButtonDynamic/ButtonDynamic.vue'
import DynamicTitle from '@/components/DynamicTitle/DynamicTitle.vue'
import EditableTable from '@/components/EditableTable/EditableTable.vue'
import HeaderInfo from '@/components/HeaderInfo/HeaderInfo.vue'
import MainContainer from '@/components/MainContainer/MainContainer.vue'
import PreviewLabels from '@/components/PreviewLabels/PreviewLabels.vue'
import ScreeningStatusLabel from '@/components/ScreeningStatusLabel/ScreeningStatusLabel.vue'
import TicketStatusTags from '@/components/TicketStatusTags/TicketStatusTags.vue'
import TopHeader from '@/components/TopHeader/TopHeader.vue'
import CryobeaconNumber from '@/components/CryoBeaconNumber/CryoBeaconNumber.vue'
import ModalWindow from '@/components/ModalWindow/ModalWindow.vue'
import CustomSelect from '@/components/CustomSelect/CustomSelect.vue'
import LoadingUi from '@/components/LoadingUi/LoadingUi.vue'
import {
  EMR_SOURCE_ID,
  EXISTING_BEACON_STEP,
  METHOD_IMPORT,
  METHOD_DONATE,
  BIOPSY_RESULT,
  SPECIMEN_NOTES,
  CRYOROBOT,
  CRYODEVICE,
  METHOD_FREEZE,
  CRYOBEACON
} from '@/constants'
import * as ss from '@/config/session-storage-help'
import { selectEmbryoTypeLabel } from '@/utils'
import { STEP_SELECTED_TICKET } from '@/constants/ticketSteps'
import { executeNextTicketStep, executePreviousTicketStep } from '@/helpers/manageTicket'
import { useTicket, useGetDestination, useValidateDestination } from '@/composables'
import {
  getBreadcrumbs,
  isNumCryoGreaterThanMaxCryo,
  activeBeaconCounterMsg,
  getScreeningStatusBeacons
} from '@/helpers/newTicketHelpers'

export default {
  name: 'DetailsView',
  components: {
    ActionBar,
    BreadCrumbs,
    ButtonDynamic,
    DynamicTitle,
    EditableTable,
    HeaderInfo,
    MainContainer,
    PreviewLabels,
    ScreeningStatusLabel,
    TicketStatusTags,
    TopHeader,
    CryobeaconNumber,
    ModalWindow,
    CustomSelect,
    LoadingUi
  },
  setup() {
    const { ticket, replaceBeaconInBeaconList, updateTicketBeacons, activeBeacon, saveTicket } =
      useTicket()
    const { destinationOptions, onSelectDestination, selectedDestination, resetDestination } =
      useGetDestination()
    const { validateUnitSpace, setUnitSpaceValidationFailed, unitSpaceValidationFailed } =
      useValidateDestination()

    return {
      ticket,
      replaceBeaconInBeaconList,
      updateTicketBeacons,
      saveTicket,
      activeBeacon,
      destinationOptions,
      onSelectDestination,
      resetDestination,
      selectedDestination,
      validateUnitSpace,
      setUnitSpaceValidationFailed,
      unitSpaceValidationFailed
    }
  },
  data() {
    return {
      modalEdit: [SPECIMEN_NOTES, BIOPSY_RESULT, 'freeText'],
      showPrintLabelModal: false,
      fromRouteName: undefined,
      loaderMessage: `Verifying ${CRYOROBOT} space availability`,
      CRYODEVICE,
      isModalOpen: false,
      CRYOBEACON,
      isLoaderVisible: false
    }
  },
  async mounted() {
    this.activeBeacon = this.beacons.find((beacon) => beacon.active)

    if (this.selectedMethod === METHOD_DONATE) {
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep
      })

      this.$router.push({ name: nextStepPath })
    }

    this.resetLabels()
  },
  watch: {
    destinationOptions() {
      // Reset Destination after destinationOptions are updated
      this.resetDestination()
    }
  },
  computed: {
    // TODO: remove this getters and use the ones in ticketModule
    ...mapGetters('newTicketModule', [
      'currentTicketStep',
      'selectedMethod',
      'selectedPatient',
      'selectedSpecimenType',
      'selectedCryoDeviceType',
      'donorId',
      'source',
      'shippingSourceId'
    ]),
    ...mapGetters('selectedTicketsModule', ['selectedTickets']),
    // This view is being used for completing EMR tickets, that does not use ticketModule
    ...mapGetters('newTicketModule', { newTicketBeacons: 'beacons' }),
    ...mapGetters('specimensModule', ['embryoTypes']),
    ...mapGetters('patientsModule', ['externalClinics']),
    ...mapGetters('ticketModule', { ticketBeacons: 'beacons' }),
    beacons() {
      if (this.newTicketBeacons?.length && !this.isEMRTicket) {
        return this.newTicketBeacons
      }
      return [this.activeBeacon]
    },
    getActiveBreadcrumbs() {
      const currentBreadcrumbs = getBreadcrumbs({
        currentTicketStep: this.currentTicketStep,
        selectedMethod: this.selectedMethod
      })
      return currentBreadcrumbs
    },
    computeActiveBeaconCounterMsg() {
      return activeBeaconCounterMsg(this.beacons, false)
    },
    cryodeviceLabelsData() {
      const { cryoDevice } = this.activeBeacon

      if (!cryoDevice) {
        return []
      }

      const { identificationNumber, firstName, lastName } = this.selectedPatient[0]

      const updatedCryoDevices = cryoDevice
        .map((currentCryoDevice) => {
          const cryodeviceTypeId = Number(this.selectedCryoDeviceType.id)
          const procedureDateFrom = dayjs(this.procedureDateFrom).format('DDMMMYYYY').toUpperCase()
          const embryoTypeLabel = selectEmbryoTypeLabel(
            {
              embryo: {
                ...currentCryoDevice.embryo,
                embryoType: currentCryoDevice.embryo?.embryoType
              }
            },
            this.embryoTypes
          )

          return {
            ...currentCryoDevice,
            cryodeviceTypeId,
            identificationNumber,
            firstName,
            lastName,
            procedureDateFrom,
            embryoTypeLabel
          }
        })
        .sort((a, b) => a.cryodeviceBarcode - b.cryodeviceBarcode)
      return updatedCryoDevices
    },
    checkMethodIsImport() {
      const method = this.$ss.getFieldSessionStorage('newTicket', 'method')
      return method === METHOD_IMPORT
    },
    isDetailEditable() {
      // TODO: Define where source is set, currently only found examples of EMR setting source
      // Source is not set during freeze process
      return !this.isEMRTicket
    },
    isMethodFreeze() {
      return this.selectedMethod === METHOD_FREEZE
    },
    isMethodImport() {
      return this.selectedMethod === METHOD_IMPORT
    },
    buttonText() {
      return this.isMethodImport ? 'Next' : 'Print Labels'
    },
    buttonIcon() {
      return this.isMethodImport ? 'arrow-circle-right' : 'print'
    },
    isButtonDisabled() {
      const isDisabled = !this.areMandatoryFieldsValid()
      return isDisabled
    },
    cryoDeviceTypeValue() {
      return this.selectedCryoDeviceType?.value || ''
    },
    isEMRTicket() {
      try {
        const { source } = ss.getFieldSessionStorage('process', 'selectedTicketToEdit')
        return source === EMR_SOURCE_ID
      } catch (error) {
        return false
      }
    },
    currentDonorId() {
      const donorId = this.donorId ? this.donorId : this.getDonorIdFromSelectedTicket()
      return donorId
    },
    currentClinicName() {
      const clinicId = this.shippingSourceId
        ? Number(this.shippingSourceId)
        : this.getShippingSourceIdFromSelectedTicket()
      const currentClinic = this.externalClinics.find((clinic) => clinic.id === clinicId)
      return currentClinic?.name
    },
    defaultDestinationValue() {
      const defaultCryorobot = this.destinationOptions.find((cryorobot) => cryorobot.defaultRobot)
      return defaultCryorobot?.value || ''
    }
  },
  methods: {
    ...mapActions('spinnerModule', ['displaySpinner', 'hideSpinner']),
    ...mapMutations('ticketModule', ['setBeacons']),
    getDonorIdFromSelectedTicket() {
      const [
        {
          specimens: [
            {
              sources: [{ donorId }]
            }
          ]
        }
      ] = this.selectedTickets
      return donorId
    },
    getShippingSourceIdFromSelectedTicket() {
      const [
        {
          specimens: [
            {
              sources: [{ shippingSourceId }]
            }
          ]
        }
      ] = this.selectedTickets
      return Number(shippingSourceId)
    },
    async handleBeaconUpdate({ isOutsideLabels = false } = {}) {
      const maxCryodevicesPerBeacon =
        ss.getFieldSessionStorage('siteProperties')['ticket.cryodevice.max-number']
      const hasSurpassedMaxCryoDevicesAllowed = isNumCryoGreaterThanMaxCryo({
        beacons: this.beacons,
        maxCryodevicesPerBeacon
      })
      if (hasSurpassedMaxCryoDevicesAllowed) {
        this.handleSelectedBeacons({ isScanBeacon: true })
        return
      }

      if (this.isMethodImport) {
        this.displaySpinner('Validating')

        try {
          const beaconResponse = await getScreeningStatusBeacons({
            beacons: this.beacons,
            patientId: this.selectedPatient[0].globalPatientNumber
          })

          this.hideSpinner()

          if (beaconResponse === EXISTING_BEACON_STEP) {
            // AddExistingBeacon screen
            this.handleSelectedBeacons()
          } else {
            this.handleSelectedBeacons({ isScanBeacon: true })
          }
          return
        } catch (error) {
          this.hideSpinner()
        }
      } else if (isOutsideLabels) {
        this.handleContinue()
      } else {
        this.showPrintLabelModal = true
      }
    },
    handleOutsideLabels() {
      this.isModalOpen = true
    },
    closeModalWindow() {
      this.isModalOpen = false
    },
    handleConfirmOutsideLabels() {
      const updatedActiveBeacon = {
        ...this.activeBeacon,
        externalCryolabel: true,
        cryoDevice: [
          ...this.activeBeacon.cryoDevice.map((cryoDevice) => ({
            ...cryoDevice,
            originalCryodeviceBarcode: cryoDevice.cryodeviceBarcode,
            metadata: {
              ...(cryoDevice.metadata || {}),
              externalCryolabel: true
            }
          }))
        ]
      }
      this.activeBeacon = updatedActiveBeacon
      const beacons = this.replaceBeaconInBeaconList(this.beacons, this.activeBeacon)
      if (this.ticket.ticketId) {
        this.ticket = this.updateTicketBeacons(this.ticket, beacons)
        this.saveTicket(this.ticket)
      }
      this.isModalOpen = false
      this.handleBeaconUpdate({ isOutsideLabels: true })
    },
    resetLabels() {
      if (!this.activeBeacon) {
        return
      }
      let updatedActiveBeacon = {
        ...this.activeBeacon,
        cryoDevice: this.activeBeacon.cryoDevice.map((cryoDevice) => ({
          ...cryoDevice,
          cryodeviceBarcodeValidation: undefined
        }))
      }
      if (updatedActiveBeacon.externalCryolabel) {
        updatedActiveBeacon = {
          ...updatedActiveBeacon,
          externalCryolabel: undefined,
          cryoDevice: updatedActiveBeacon.cryoDevice.map((cryoDevice) => ({
            ...cryoDevice,
            cryodeviceBarcode: cryoDevice.originalCryodeviceBarcode,
            originalCryodeviceBarcode: undefined,
            metadata: {
              ...(cryoDevice.metadata || {}),
              externalCryolabel: undefined // use undefined instead of false so axios can remove this field on the payload
            }
          }))
        }
      }

      this.activeBeacon = updatedActiveBeacon
      const beacons = this.replaceBeaconInBeaconList(this.beacons, this.activeBeacon)
      if (this.ticket.ticketId) {
        this.ticket = this.updateTicketBeacons(this.ticket, beacons)
        this.saveTicket(this.ticket)
      }
    },
    handleContinue() {
      this.handleSelectedBeacons()
    },
    goToFlightboard() {
      this.$router.push('/flight-board')
    },
    async handleSelectedBeacons({ isScanBeacon = false } = {}) {
      const currentBeacons = this.beacons.map((beacon) => {
        const { beaconId } = beacon

        if (beaconId === this.activeBeacon.beaconId) {
          return { ...this.activeBeacon, selectedDestination: this.selectedDestination }
        }

        return beacon
      })

      this.setBeacons(currentBeacons)
      const nextStepPath = await executeNextTicketStep({
        selectedMethod: this.selectedMethod,
        currentTicketStep: this.currentTicketStep,
        beacons: currentBeacons,
        isScanBeacon
      })
      this.$router.push({ name: nextStepPath })
    },
    handleClickBack() {
      let previousStepPath
      switch (this.isEMRTicket) {
        case true:
          previousStepPath = executePreviousTicketStep({
            selectedMethod: this.selectedMethod,
            currentTicketStep: this.currentTicketStep,
            fromRouteName: STEP_SELECTED_TICKET
          })
          break
        default: {
          previousStepPath = executePreviousTicketStep({
            selectedMethod: this.selectedMethod,
            currentTicketStep: this.currentTicketStep
          })
          break
        }
      }

      this.$router.replace({ name: previousStepPath })
    },
    handleSpecimenChange(beaconSpecimens) {
      const updatedActiveBeacon = {
        ...this.activeBeacon,
        cryoDevice: [...beaconSpecimens]
      }
      this.activeBeacon = updatedActiveBeacon
      const beacons = this.replaceBeaconInBeaconList(this.beacons, this.activeBeacon)
      if (this.ticket.ticketId) {
        this.ticket = this.updateTicketBeacons(this.ticket, beacons)
        this.saveTicket(this.ticket)
      }
    },
    handleClosePrintModal() {
      this.showPrintLabelModal = false
    },
    hasEmbryoTypeOnCryoDevices(beacon) {
      const result = !!beacon.cryoDevice?.every((cryoDevice) =>
        Boolean(cryoDevice.embryo?.embryoType)
      )
      return result
    },
    hasCryoDates() {
      const activeBeaconCryoDevices = this.activeBeacon.cryoDevice
      const hasCryoDates = !!activeBeaconCryoDevices?.every((cryoDevice) =>
        Boolean(cryoDevice.cryoDate)
      )
      return hasCryoDates
    },
    areMandatoryFieldsValid() {
      let areFieldsValid = true

      if (this.isMethodImport) {
        areFieldsValid = this.hasCryoDates()
      }

      return areFieldsValid
    },
    async onChangeDestination(value) {
      this.onSelectDestination(value)
      const updatedActiveBeacon = {
        ...this.activeBeacon,
        selectedDestination: this.selectedDestination
      }
      this.activeBeacon = updatedActiveBeacon
      if (value) {
        const validationPayload = {
          screeningStatus: this.cryodeviceLabelsData[0].screeningStatus,
          containerId: value
        }
        this.isLoaderVisible = true
        const hasUnitAvailableSpace = await this.validateUnitSpace(validationPayload)
        this.isSpaceAvailableInSelectedUnit = hasUnitAvailableSpace
        this.isLoaderVisible = false
      } else {
        this.isButtonDisabled = true
        this.setUnitSpaceValidationFailed(false)
      }
    }
  }
}
</script>
